import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import BetaSignup from "components/cta/GetStarted.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Hero />
      <FeatureStats />
      {/* Add featured section for all the servies that Jotify has been reawtured on */}
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Users <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc: require("../images/ryan.svg"),
            heading: "Amazing User Experience",
            quote:
              '"Such a great note taking app, Seriously! Honestly the aesthetics alone make it worth the 99 cents! Not to mention additional functionality like syncing with iCloud and Touch/Face ID login!"',
            customerName: "Rclyay",
            customerTitle: "United States",
          },
          {
            stars: 5,
            profileImageSrc: require("../images/hance.svg"),
            heading: "Beautiful Notes App",
            quote:
              '"Vibrant dark mode looks incredible and saving notes is low key addicting. I set a note to brush my teeth just because it was easy and I could."',
            customerName: "Big Ole Kenny B",
            customerTitle: "United States",
          },
          {
            stars: 5,
            profileImageSrc: require("../images/girl.svg"),
            heading: "Beautiful Notes App",
            quote:
              '"This app is my go-to note app. I use it to quickly put down ideas or to keep lists as I use this app nearly daily. If I want to look at a note later, I just set a reminder, and the reminder is that note. Such an amazing idea. If you are on the fence about downloading this app, just try it out. You may love it more than I do!"',
            customerName: "jcr3468",
            customerTitle: "United States",
          },
        ]}
      />
      <Blog
        subheading="Software"
        heading={
          <>
            Our Favorite <HighlightedText>Projects</HighlightedText>
          </>
        }
      />
      <ContactUsForm description="Questions, concerns, feedback? If you have something important to share, shoot us an email, and we will get back to you as soon as possible!" />
      <BetaSignup />
      <Footer />
    </AnimationRevealPage>
  );
};
