import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/mobile-dev2.svg";
//eslint-disable-next-line
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

import HeaderBase, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";

const Header = tw(HeaderBase)`max-w-none`;

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

//eslint-disable-next-line
const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton }) => {
  const buttonRoundedCss = false && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink to="help">About</NavLink>
      <NavLink href="/#stats">Featured</NavLink>
      <NavLink href="/#testimonials">Testimonials</NavLink>
      <NavLink href="/#software">Software</NavLink>
      <NavLink href="/#testflight">Testflight</NavLink>
    </NavLinks>,
    <NavLinks key={1}>
      <PrimaryLink css={buttonRoundedCss} href="/#contact">
        Contact Us
      </PrimaryLink>
    </NavLinks>,
  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              We create beautiful software{" "}
              <span tw="text-primary-500">for you.</span>
            </Heading>
            <Paragraph>
              At Leathal Enterprises, we specialize in creating innovative and
              easy to use software for the everyday consumer.
            </Paragraph>
            <Actions>
              <PrimaryButton as="a" href="#stats">
                Learn More
              </PrimaryButton>
            </Actions>
            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-11/12 max-w-lg xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
